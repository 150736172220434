"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTradeRate = void 0;
const getTradeQuote_1 = require("../getTradeQuote/getTradeQuote");
const getTradeRate = async (input, deps, lifiChainMap) => {
    const ratesResult = await (0, getTradeQuote_1.getTrade)({
        input,
        deps,
        lifiChainMap,
    });
    return ratesResult.map(rates => rates.map(rate => ({
        ...rate,
        quoteOrRate: 'rate',
        steps: rate.steps.map(step => step),
    })));
};
exports.getTradeRate = getTradeRate;
