"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFullAppData = exports.getAffiliateAppDataFragmentByChainId = exports.cowSwapTokenToAssetId = exports.signCowOrderCancellation = exports.signCowOrder = exports.signCowMessage = exports.assertGetCowNetwork = exports.getCowNetwork = void 0;
const app_data_1 = require("@cowprotocol/app-data");
const caip_1 = require("@shapeshiftoss/caip");
const chain_adapters_1 = require("@shapeshiftoss/chain-adapters");
const types_1 = require("@shapeshiftoss/types");
const utils_1 = require("@shapeshiftoss/utils");
const ethers_1 = require("ethers");
const viem_1 = require("viem");
const helpers_1 = require("../swappers/utils/helpers/helpers");
const constants_1 = require("./constants");
__exportStar(require("./constants"), exports);
const getCowNetwork = (chainId) => {
    switch (chainId) {
        case types_1.KnownChainIds.EthereumMainnet:
            return types_1.CowNetwork.Mainnet;
        case types_1.KnownChainIds.GnosisMainnet:
            return types_1.CowNetwork.Xdai;
        case types_1.KnownChainIds.ArbitrumMainnet:
            return types_1.CowNetwork.ArbitrumOne;
        default:
            return;
    }
};
exports.getCowNetwork = getCowNetwork;
const cowDomain = (chainId, verifyingContract) => {
    return {
        name: 'Gnosis Protocol',
        version: 'v2',
        chainId,
        verifyingContract,
    };
};
const assertGetCowNetwork = (chainId) => {
    const maybeNetwork = (0, exports.getCowNetwork)(chainId);
    if (!maybeNetwork)
        throw Error('Unsupported chain');
    return maybeNetwork;
};
exports.assertGetCowNetwork = assertGetCowNetwork;
const signCowMessage = async (typedData, chainAdapter, accountMetadata, wallet) => {
    const { bip44Params } = accountMetadata;
    const typedDataToSign = {
        addressNList: (0, chain_adapters_1.toAddressNList)(chainAdapter.getBip44Params(bip44Params)),
        typedData,
    };
    const signTypedDataInput = {
        typedDataToSign,
        wallet,
    };
    const signedTypeData = await chainAdapter.signTypedData(signTypedDataInput);
    // Passing the signature through split/join to normalize the `v` byte.
    // Some wallets do not pad it with `27`, which causes a signature failure
    // `splitSignature` pads it if needed, and `joinSignature` simply puts it back together
    const signature = ethers_1.ethers.Signature.from(ethers_1.ethers.Signature.from(signedTypeData)).serialized;
    return signature;
};
exports.signCowMessage = signCowMessage;
const getSignTypeDataPayload = (chainId, primaryType, types, message) => {
    const { chainReference } = (0, caip_1.fromChainId)(chainId);
    const signingDomain = Number(chainReference);
    const typedDataDomain = cowDomain(signingDomain, constants_1.COW_SWAP_SETTLEMENT_ADDRESS);
    return {
        // Mismatch of types between ethers' TypedDataDomain and TypedData :shrugs:
        domain: typedDataDomain,
        primaryType,
        types: {
            ...types,
            EIP712Domain: [
                { name: 'name', type: 'string' },
                { name: 'version', type: 'string' },
                { name: 'chainId', type: 'uint256' },
                { name: 'verifyingContract', type: 'address' },
            ],
        },
        message,
    };
};
const signCowOrder = async (unsignedOrderCreation, chainId, signMessage) => {
    // Removes the types that aren't part of GpV2Order types or structured signing will fail
    const { signingScheme, quoteId, appDataHash, appData, receiver, ...rest } = unsignedOrderCreation;
    if (!appDataHash)
        throw Error('missing appDataHash');
    if (!receiver)
        throw Error('missing receiver');
    const message = {
        ...rest,
        receiver,
        // The order we're signing requires the appData to be a hash, not the stringified doc
        // However, the request we're making to *send* the order to the API requires both appData and appDataHash in their original form
        // see https://github.com/cowprotocol/cowswap/blob/a11703f4e93df0247c09d96afa93e13669a3c244/apps/cowswap-frontend/src/legacy/utils/trade.ts#L236
        appData: appDataHash,
    };
    const typedData = getSignTypeDataPayload(chainId, types_1.TypedDataPrimaryType.Order, { Order: constants_1.ORDER_TYPE_FIELDS }, message);
    const signature = await signMessage(typedData);
    return signature;
};
exports.signCowOrder = signCowOrder;
const signCowOrderCancellation = async (orderUid, chainId, signMessage) => {
    const typedData = getSignTypeDataPayload(chainId, types_1.TypedDataPrimaryType.OrderCancellations, { OrderCancellations: constants_1.CANCELLATIONS_TYPE_FIELDS }, { orderUids: [orderUid] });
    const signature = await signMessage(typedData);
    return signature;
};
exports.signCowOrderCancellation = signCowOrderCancellation;
const cowSwapTokenToAssetId = (chainId, cowSwapToken) => {
    const { chainNamespace, chainReference } = (0, caip_1.fromChainId)(chainId);
    return cowSwapToken.toLowerCase() === constants_1.COW_SWAP_NATIVE_ASSET_MARKER_ADDRESS.toLowerCase()
        ? (0, caip_1.toAssetId)({
            chainId,
            assetNamespace: 'slip44',
            assetReference: (0, utils_1.getNativeFeeAssetReference)(chainNamespace, chainReference),
        })
        : (0, caip_1.toAssetId)({
            chainId,
            assetNamespace: caip_1.ASSET_NAMESPACE.erc20,
            assetReference: cowSwapToken,
        });
};
exports.cowSwapTokenToAssetId = cowSwapTokenToAssetId;
const getAffiliateAppDataFragmentByChainId = ({ affiliateBps, chainId, }) => {
    const hasAffiliateFee = (0, utils_1.bnOrZero)(affiliateBps).gt(0);
    if (!hasAffiliateFee)
        return {};
    return {
        partnerFee: {
            bps: Number(affiliateBps),
            recipient: (0, helpers_1.getTreasuryAddressFromChainId)(chainId),
        },
    };
};
exports.getAffiliateAppDataFragmentByChainId = getAffiliateAppDataFragmentByChainId;
const generateAppDataFromDoc = async (doc) => {
    const appData = await (0, app_data_1.stringifyDeterministic)(doc);
    const appDataKeccak256 = (0, viem_1.keccak256)((0, viem_1.stringToBytes)(appData));
    return { fullAppData: appData, appDataKeccak256 };
};
const metadataApi = new app_data_1.MetadataApi();
// See https://api.cow.fi/docs/#/default/post_api_v1_quote / https://github.com/cowprotocol/app-data
const getFullAppData = async (slippageTolerancePercentage, affiliateAppDataFragment, orderClass1) => {
    const APP_CODE = 'shapeshift';
    const orderClass = { orderClass: orderClass1 };
    const quote = {
        slippageBips: (0, utils_1.convertDecimalPercentageToBasisPoints)(slippageTolerancePercentage).toNumber(),
    };
    const appDataDoc = await metadataApi.generateAppDataDoc({
        appCode: APP_CODE,
        metadata: {
            quote,
            orderClass,
            ...affiliateAppDataFragment,
        },
    });
    const { fullAppData, appDataKeccak256 } = await generateAppDataFromDoc(appDataDoc);
    return { appDataHash: appDataKeccak256, appData: fullAppData };
};
exports.getFullAppData = getFullAppData;
