"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTradeQuote = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const utils_1 = require("@shapeshiftoss/utils");
const monads_1 = require("@sniptt/monads");
const uuid_1 = require("uuid");
const types_1 = require("../../../types");
const utils_2 = require("../../../utils");
const constants_1 = require("../utils/constants");
const helpers_1 = require("../utils/helpers");
const getTradeQuote = async (input, deps) => {
    const { sellAsset, buyAsset, affiliateBps, receiveAddress, accountNumber, sendAddress, sellAmountIncludingProtocolFeesCryptoBaseUnit: sellAmount, slippageTolerancePercentageDecimal: _slippageTolerancePercentageDecimal, } = input;
    const { assetsById } = deps;
    const jupiterUrl = deps.config.REACT_APP_JUPITER_API_URL;
    const solAsset = assetsById[caip_1.solAssetId];
    if (accountNumber === undefined) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `accountNumber is required`,
            code: types_1.TradeQuoteError.UnknownError,
        }));
    }
    if (!(0, helpers_1.isSupportedChainId)(sellAsset.chainId)) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `unsupported chainId`,
            code: types_1.TradeQuoteError.UnsupportedChain,
            details: { chainId: sellAsset.chainId },
        }));
    }
    if (!(0, helpers_1.isSupportedChainId)(buyAsset.chainId)) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `unsupported chainId`,
            code: types_1.TradeQuoteError.UnsupportedChain,
            details: { chainId: sellAsset.chainId },
        }));
    }
    if (!sendAddress) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `sendAddress is required`,
            code: types_1.TradeQuoteError.UnknownError,
        }));
    }
    if (!solAsset) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `solAsset is required`,
            code: types_1.TradeQuoteError.UnknownError,
        }));
    }
    const maybePriceResponse = await (0, helpers_1.getJupiterPrice)({
        apiUrl: jupiterUrl,
        sourceAsset: sellAsset.assetId === caip_1.solAssetId ? caip_1.wrappedSolAssetId : sellAsset.assetId,
        destinationAsset: buyAsset.assetId === caip_1.solAssetId ? caip_1.wrappedSolAssetId : buyAsset.assetId,
        commissionBps: affiliateBps,
        amount: sellAmount,
        slippageBps: _slippageTolerancePercentageDecimal
            ? (0, utils_1.convertDecimalPercentageToBasisPoints)(_slippageTolerancePercentageDecimal).toFixed()
            : undefined,
    });
    if (maybePriceResponse.isErr()) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: 'Quote request failed',
            code: types_1.TradeQuoteError.NoRouteFound,
        }));
    }
    const { data: priceResponse } = maybePriceResponse.unwrap();
    const slippageTolerancePercentageDecimal = 
    // Divide by 100 to get actual decimal percentage from bps
    // e.g for 0.5% bps, Jupiter represents this as 50. 50/100 = 0.5, then we div by 100 again to honour our decimal format e.g 0.5/100 = 0.005
    (0, utils_1.bn)(priceResponse.slippageBps).div(100).div(100).toString();
    const adapter = deps.assertGetSolanaChainAdapter(sellAsset.chainId);
    const { instructions, addressLookupTableAddresses } = await (0, helpers_1.createSwapInstructions)({
        priceResponse,
        sendAddress,
        receiveAddress,
        affiliateBps,
        buyAsset,
        sellAsset,
        adapter,
        jupiterUrl,
    });
    const getFeeData = async () => {
        const sellAdapter = deps.assertGetSolanaChainAdapter(sellAsset.chainId);
        const getFeeDataInput = {
            to: '',
            value: '0',
            chainSpecific: {
                from: sendAddress,
                addressLookupTableAccounts: addressLookupTableAddresses,
                instructions,
            },
        };
        const feeData = await sellAdapter.getFeeData(getFeeDataInput);
        return {
            txFee: feeData.fast.txFee,
            chainSpecific: {
                computeUnits: (0, utils_1.bnOrZero)(feeData.fast.chainSpecific.computeUnits)
                    .times(constants_1.JUPITER_COMPUTE_UNIT_MARGIN_MULTIPLIER)
                    .toFixed(0),
                priorityFee: feeData.fast.chainSpecific.priorityFee,
            },
        };
    };
    const protocolFees = priceResponse.routePlan.reduce((acc, route) => {
        const feeAssetId = (0, caip_1.toAssetId)({
            assetReference: route.swapInfo.feeMint,
            assetNamespace: caip_1.ASSET_NAMESPACE.splToken,
            chainNamespace: caip_1.CHAIN_NAMESPACE.Solana,
            chainReference: caip_1.CHAIN_REFERENCE.SolanaMainnet,
        });
        const feeAsset = assetsById[feeAssetId];
        // If we can't find the feeAsset, we can't provide a protocol fee to display
        // But these fees exists at protocol level, it's mostly to make TS happy as we should have the market data and assets
        if (!feeAsset)
            return acc;
        acc[feeAssetId] = {
            requiresBalance: false,
            amountCryptoBaseUnit: (0, utils_1.bnOrZero)(route.swapInfo.feeAmount).toFixed(0),
            asset: feeAsset,
        };
        return acc;
    }, {});
    const quotes = [];
    const feeData = await getFeeData();
    const rate = (0, utils_2.getInputOutputRate)({
        sellAmountCryptoBaseUnit: priceResponse.inAmount,
        buyAmountCryptoBaseUnit: priceResponse.outAmount,
        sellAsset,
        buyAsset,
    });
    const accountCreationFees = (0, helpers_1.calculateAccountCreationCosts)(instructions);
    if (accountCreationFees !== '0') {
        const solProtocolFeeAmount = (0, utils_1.bnOrZero)(protocolFees[caip_1.solAssetId]?.amountCryptoBaseUnit);
        protocolFees[caip_1.solAssetId] = {
            requiresBalance: true,
            amountCryptoBaseUnit: (0, utils_1.bnOrZero)(solProtocolFeeAmount).plus(accountCreationFees).toFixed(),
            asset: solAsset,
        };
    }
    const tradeQuote = {
        id: (0, uuid_1.v4)(),
        quoteOrRate: 'quote',
        rate,
        potentialAffiliateBps: affiliateBps,
        affiliateBps,
        receiveAddress,
        slippageTolerancePercentageDecimal,
        steps: [
            {
                accountNumber,
                buyAmountBeforeFeesCryptoBaseUnit: priceResponse.outAmount,
                buyAmountAfterFeesCryptoBaseUnit: priceResponse.outAmount,
                sellAmountIncludingProtocolFeesCryptoBaseUnit: priceResponse.inAmount,
                jupiterQuoteResponse: priceResponse,
                jupiterTransactionMetadata: {
                    addressLookupTableAddresses,
                    instructions,
                },
                feeData: {
                    protocolFees,
                    networkFeeCryptoBaseUnit: feeData.txFee,
                    chainSpecific: feeData.chainSpecific,
                },
                rate,
                source: types_1.SwapperName.Jupiter,
                buyAsset,
                sellAsset,
                allowanceContract: '0x0',
                // Swap are so fasts on solana that times are under 100ms displaying 0 or very small amount of time is not user friendly
                estimatedExecutionTimeMs: undefined,
            },
        ],
    };
    quotes.push(tradeQuote);
    return (0, monads_1.Ok)(quotes);
};
exports.getTradeQuote = getTradeQuote;
