"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromAddressNList = exports.toAddressNList = exports.fromPath = exports.toPath = exports.toRootDerivationPath = void 0;
const hdwallet_core_1 = require("@shapeshiftoss/hdwallet-core");
const toRootDerivationPath = (bip44Params) => {
    const { purpose, coinType, accountNumber } = bip44Params;
    if (typeof purpose === 'undefined')
        throw new Error('purpose is required');
    if (typeof coinType === 'undefined')
        throw new Error('coinType is required');
    if (typeof accountNumber === 'undefined')
        throw new Error('accountNumber is required');
    return `m/${purpose}'/${coinType}'/${accountNumber}'`;
};
exports.toRootDerivationPath = toRootDerivationPath;
const toPath = (bip44Params) => {
    const { isChange, addressIndex } = bip44Params;
    let path = (0, exports.toRootDerivationPath)(bip44Params);
    if (isChange !== undefined) {
        path = path.concat(`/${Number(isChange)}`);
        if (addressIndex !== undefined)
            path = path.concat(`/${addressIndex}`);
    }
    return path;
};
exports.toPath = toPath;
const fromPath = (path) => {
    const parts = path.split('/').slice(1); // discard the m/
    if (parts.length < 3 || parts.length > 5) {
        throw new Error(`path has ${parts.length} parts, expected 3 to 5`);
    }
    const [purpose, coinType, accountNumber, change, addressIndex] = parts.map(part => Number(part.replace("'", '')));
    const isChange = change !== undefined ? Boolean(change) : undefined;
    return { purpose, coinType, accountNumber, isChange, addressIndex };
};
exports.fromPath = fromPath;
const toAddressNList = (bip44Params) => {
    return (0, hdwallet_core_1.bip32ToAddressNList)((0, exports.toPath)(bip44Params));
};
exports.toAddressNList = toAddressNList;
const fromAddressNList = (addressNList) => {
    return (0, exports.fromPath)((0, hdwallet_core_1.addressNListToBIP32)(addressNList));
};
exports.fromAddressNList = fromAddressNList;
