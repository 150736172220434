"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUtxoTxFees = void 0;
const utils_1 = require("@shapeshiftoss/utils");
const getUtxoTxFees = async ({ sellAmountCryptoBaseUnit, sellAdapter, pubKey, }) => {
    // Can't do coinselect simulation without a pubkey
    if (!pubKey)
        return {
            networkFeeCryptoBaseUnit: undefined,
        };
    const getFeeDataInput = {
        // One of many vault addresses - just used as a placeholder for the sake of loosely estimating fees - we *need* a *to* address for simulation or this will throw
        to: 'bc1pfh5x55a3v92klcrdy5yv6yrt7fzr0g929klkdtapp3njfyu4qsyq8qacyf',
        value: sellAmountCryptoBaseUnit,
        chainSpecific: { pubkey: pubKey },
    };
    const feeDataOptions = await sellAdapter.getFeeData(getFeeDataInput);
    const feeData = feeDataOptions['fast'];
    return {
        networkFeeCryptoBaseUnit: feeData.txFee,
        chainSpecific: {
            satsPerByte: feeData.chainSpecific.satoshiPerByte,
            byteCount: (0, utils_1.bn)(feeData.txFee).dividedBy(feeData.chainSpecific.satoshiPerByte).dp(0).toString(),
        },
    };
};
exports.getUtxoTxFees = getUtxoTxFees;
