"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getThorTradeRate = exports.isThorTradeRate = void 0;
const utils_1 = require("@shapeshiftoss/utils");
const monads_1 = require("@sniptt/monads");
const types_1 = require("../../../types");
const utils_2 = require("../../../utils");
const constants_1 = require("../constants");
const getL1Rate_1 = require("../utils/getL1Rate");
const getL1ToLongtailRate_1 = require("../utils/getL1ToLongtailRate");
const getLongtailRate_1 = require("../utils/getLongtailRate");
const longTailHelpers_1 = require("../utils/longTailHelpers");
const poolAssetHelpers_1 = require("../utils/poolAssetHelpers/poolAssetHelpers");
const thorService_1 = require("../utils/thorService");
const isThorTradeRate = (quote) => !!quote && 'tradeType' in quote && 'vault' in quote;
exports.isThorTradeRate = isThorTradeRate;
const getThorTradeRate = async (input, deps) => {
    const thorchainSwapLongtailEnabled = deps.config.REACT_APP_FEATURE_THORCHAINSWAP_LONGTAIL;
    const thorchainSwapL1ToLongtailEnabled = deps.config.REACT_APP_FEATURE_THORCHAINSWAP_L1_TO_LONGTAIL;
    const { sellAsset, buyAsset } = input;
    if (!constants_1.sellSupportedChainIds[sellAsset.chainId] || !constants_1.buySupportedChainIds[buyAsset.chainId]) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: 'Unsupported chain',
            code: types_1.TradeQuoteError.UnsupportedChain,
        }));
    }
    const daemonUrl = deps.config.REACT_APP_THORCHAIN_NODE_URL;
    const maybePoolsResponse = await thorService_1.thorService.get(`${daemonUrl}/lcd/thorchain/pools`);
    if (maybePoolsResponse.isErr())
        return (0, monads_1.Err)(maybePoolsResponse.unwrapErr());
    const { data: poolsResponse } = maybePoolsResponse.unwrap();
    const buyPoolId = (0, poolAssetHelpers_1.assetIdToPoolAssetId)({ assetId: buyAsset.assetId });
    const sellPoolId = (0, poolAssetHelpers_1.assetIdToPoolAssetId)({ assetId: sellAsset.assetId });
    // If one or both of these are undefined it means we are tradeing one or more long-tail ERC20 tokens
    const sellAssetPool = poolsResponse.find(pool => pool.asset === sellPoolId);
    const buyAssetPool = poolsResponse.find(pool => pool.asset === buyPoolId);
    const tradeType = thorchainSwapLongtailEnabled
        ? (0, longTailHelpers_1.getTradeType)(sellAssetPool, buyAssetPool, sellPoolId, buyPoolId)
        : longTailHelpers_1.TradeType.L1ToL1;
    if (tradeType === undefined) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: 'Unknown trade type',
            code: types_1.TradeQuoteError.UnsupportedTradePair,
        }));
    }
    if ((!buyPoolId && tradeType !== longTailHelpers_1.TradeType.L1ToLongTail) ||
        (!sellPoolId && tradeType !== longTailHelpers_1.TradeType.LongTailToL1)) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: 'Unsupported trade pair',
            code: types_1.TradeQuoteError.UnsupportedTradePair,
        }));
    }
    const streamingInterval = sellAssetPool && buyAssetPool
        ? (() => {
            const sellAssetDepthBps = sellAssetPool.derived_depth_bps;
            const buyAssetDepthBps = buyAssetPool.derived_depth_bps;
            const swapDepthBps = (0, utils_1.bn)(sellAssetDepthBps).plus(buyAssetDepthBps).div(2);
            // Low health for the pools of this swap - use a longer streaming interval
            if (swapDepthBps.lt(5000))
                return 10;
            // Moderate health for the pools of this swap - use a moderate streaming interval
            if (swapDepthBps.lt(9000) && swapDepthBps.gte(5000))
                return 5;
            // Pool is at 90%+ health - use a 1 block streaming interval
            return 1;
        })()
        : // TODO: One of the pools is RUNE - use the as-is 10 until we work out how best to handle this
            10;
    switch (tradeType) {
        case longTailHelpers_1.TradeType.L1ToL1:
            return (0, getL1Rate_1.getL1Rate)(input, deps, streamingInterval, tradeType);
        case longTailHelpers_1.TradeType.LongTailToL1:
            return (0, getLongtailRate_1.getLongtailToL1Rate)(input, deps, streamingInterval);
        case longTailHelpers_1.TradeType.L1ToLongTail:
            if (!thorchainSwapL1ToLongtailEnabled)
                return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({ message: 'Not implemented yet' }));
            return (0, getL1ToLongtailRate_1.getL1ToLongtailRate)(input, deps, streamingInterval);
        case longTailHelpers_1.TradeType.LongTailToLongTail:
            return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({ message: 'Not implemented yet' }));
        default:
            (0, utils_1.assertUnreachable)(tradeType);
    }
};
exports.getThorTradeRate = getThorTradeRate;
