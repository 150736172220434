"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getQuoteOrRate = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const utils_1 = require("@shapeshiftoss/utils");
const monads_1 = require("@sniptt/monads");
const uuid_1 = require("uuid");
const constants_1 = require("../../../constants");
const types_1 = require("../../../types");
const utils_2 = require("../../../utils");
const constants_2 = require("../constants");
const chainflipService_1 = require("./chainflipService");
const getEvmTxFees_1 = require("./getEvmTxFees");
const getUtxoTxFees_1 = require("./getUtxoTxFees");
const helpers_1 = require("./helpers");
const getQuoteOrRate = async (input, deps) => {
    const { accountNumber, receiveAddress, sellAsset, buyAsset, sellAmountIncludingProtocolFeesCryptoBaseUnit, affiliateBps: commissionBps, quoteOrRate, } = input;
    if (!(0, helpers_1.isSupportedChainId)(sellAsset.chainId)) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `unsupported chainId`,
            code: types_1.TradeQuoteError.UnsupportedChain,
            details: { chainId: sellAsset.chainId },
        }));
    }
    if (!(0, helpers_1.isSupportedChainId)(buyAsset.chainId)) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `unsupported chainId`,
            code: types_1.TradeQuoteError.UnsupportedChain,
            details: { chainId: sellAsset.chainId },
        }));
    }
    if (!(0, helpers_1.isSupportedAssetId)(sellAsset.chainId, sellAsset.assetId)) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `asset '${sellAsset.name}' on chainId '${sellAsset.chainId}' not supported`,
            code: types_1.TradeQuoteError.UnsupportedTradePair,
            details: { chainId: sellAsset.chainId, assetId: sellAsset.assetId },
        }));
    }
    if (!(0, helpers_1.isSupportedAssetId)(buyAsset.chainId, buyAsset.assetId)) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `asset '${buyAsset.name}' on chainId '${buyAsset.chainId}' not supported`,
            code: types_1.TradeQuoteError.UnsupportedTradePair,
            details: { chainId: buyAsset.chainId, assetId: buyAsset.assetId },
        }));
    }
    const brokerUrl = deps.config.REACT_APP_CHAINFLIP_API_URL;
    const apiKey = deps.config.REACT_APP_CHAINFLIP_API_KEY;
    const sourceAsset = await (0, helpers_1.getChainFlipIdFromAssetId)({
        assetId: sellAsset.assetId,
        brokerUrl,
    });
    const destinationAsset = await (0, helpers_1.getChainFlipIdFromAssetId)({
        assetId: buyAsset.assetId,
        brokerUrl,
    });
    // Subtract the BaaS fee to end up at the final displayed commissionBps
    let serviceCommission = parseInt(commissionBps) - constants_2.CHAINFLIP_BAAS_COMMISSION;
    if (serviceCommission < 0)
        serviceCommission = 0;
    const maybeQuoteResponse = await chainflipService_1.chainflipService.get(`${brokerUrl}/quotes-native` +
        `?apiKey=${apiKey}` +
        `&sourceAsset=${sourceAsset}` +
        `&destinationAsset=${destinationAsset}` +
        `&amount=${sellAmountIncludingProtocolFeesCryptoBaseUnit}` +
        `&commissionBps=${serviceCommission}`);
    if (maybeQuoteResponse.isErr()) {
        const error = maybeQuoteResponse.unwrapErr();
        const cause = error.cause;
        if (cause.message.includes('code 400') &&
            cause.response.data.detail.includes('Amount outside asset bounds')) {
            return (0, monads_1.Err)((0, utils_2.createTradeAmountTooSmallErr)({
                assetId: sellAsset.assetId,
                minAmountCryptoBaseUnit: cause.response.data.errors.minimalAmountNative[0],
            }));
        }
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: 'Quote request failed',
            code: types_1.TradeQuoteError.NoRouteFound,
        }));
    }
    const { data: quoteResponse } = maybeQuoteResponse.unwrap();
    const getFeeData = async () => {
        const { chainNamespace } = (0, caip_1.fromAssetId)(sellAsset.assetId);
        switch (chainNamespace) {
            case caip_1.CHAIN_NAMESPACE.Evm: {
                const sellAdapter = deps.assertGetEvmChainAdapter(sellAsset.chainId);
                const networkFeeCryptoBaseUnit = await (0, getEvmTxFees_1.getEvmTxFees)({
                    adapter: sellAdapter,
                    supportsEIP1559: input.supportsEIP1559,
                    sendAsset: sourceAsset,
                });
                return { networkFeeCryptoBaseUnit };
            }
            case caip_1.CHAIN_NAMESPACE.Utxo: {
                const sellAdapter = deps.assertGetUtxoChainAdapter(sellAsset.chainId);
                const pubKey = input.xpub;
                return await (0, getUtxoTxFees_1.getUtxoTxFees)({
                    sellAmountCryptoBaseUnit: sellAmountIncludingProtocolFeesCryptoBaseUnit,
                    sellAdapter,
                    pubKey,
                });
            }
            case caip_1.CHAIN_NAMESPACE.Solana: {
                if (!input.sendAddress)
                    return { networkFeeCryptoBaseUnit: undefined };
                const sellAdapter = deps.assertGetSolanaChainAdapter(sellAsset.chainId);
                const getFeeDataInput = {
                    // Simulates a self-send, since we don't know the 'to' just yet at this stage
                    to: input.sendAddress,
                    value: sellAmountIncludingProtocolFeesCryptoBaseUnit,
                    chainSpecific: {
                        from: input.sendAddress,
                        tokenId: sellAsset.assetId === caip_1.solAssetId
                            ? undefined
                            : (0, caip_1.fromAssetId)(sellAsset.assetId).assetReference,
                    },
                };
                const { fast } = await sellAdapter.getFeeData(getFeeDataInput);
                return { networkFeeCryptoBaseUnit: fast.txFee };
            }
            default:
                throw new Error('Unsupported chainNamespace');
        }
    };
    const getFeeAsset = (fee) => {
        if (fee.type === 'ingress' || fee.type === 'boost')
            return sellAsset;
        if (fee.type === 'egress')
            return buyAsset;
        if (fee.type === 'liquidity' && fee.asset === sourceAsset)
            return sellAsset;
        if (fee.type === 'liquidity' && fee.asset === destinationAsset)
            return buyAsset;
        if (fee.type === 'liquidity' && fee.asset === 'usdc.eth')
            return constants_2.usdcAsset;
        if (fee.type === 'network')
            return constants_2.usdcAsset;
    };
    const getProtocolFees = (singleQuoteResponse) => {
        const protocolFees = {};
        for (const fee of singleQuoteResponse.includedFees) {
            if (fee.type === 'broker')
                continue;
            const asset = getFeeAsset(fee);
            if (!(asset.assetId in protocolFees)) {
                protocolFees[asset.assetId] = {
                    amountCryptoBaseUnit: '0',
                    requiresBalance: false,
                    asset,
                };
            }
            protocolFees[asset.assetId].amountCryptoBaseUnit = (BigInt(protocolFees[asset.assetId].amountCryptoBaseUnit) + BigInt(fee.amountNative)).toString();
        }
        return protocolFees;
    };
    const getChainflipQuoteRate = (sellAmountCryptoBaseUnit, buyAmountCryptoBaseUnit) => {
        return (0, utils_2.getInputOutputRate)({
            sellAmountCryptoBaseUnit,
            buyAmountCryptoBaseUnit,
            sellAsset,
            buyAsset,
        });
    };
    const getSwapSource = (swapType, isBoosted) => {
        if (swapType === constants_2.CHAINFLIP_REGULAR_QUOTE) {
            return isBoosted ? constants_2.CHAINFLIP_BOOST_SWAP_SOURCE : constants_2.CHAINFLIP_SWAP_SOURCE;
        }
        if (swapType === constants_2.CHAINFLIP_DCA_QUOTE) {
            return isBoosted ? constants_2.CHAINFLIP_DCA_BOOST_SWAP_SOURCE : constants_2.CHAINFLIP_DCA_SWAP_SOURCE;
        }
        return (0, utils_1.assertUnreachable)(swapType);
    };
    const getMaxBoostFee = () => {
        const { chainNamespace } = (0, caip_1.fromAssetId)(sellAsset.assetId);
        switch (chainNamespace) {
            case caip_1.CHAIN_NAMESPACE.Evm:
                return 0;
            case caip_1.CHAIN_NAMESPACE.Utxo:
                return 10;
            case caip_1.CHAIN_NAMESPACE.Solana:
                return 0;
            default:
                throw new Error('Unsupported chainNamespace');
        }
    };
    const ratesOrQuotes = [];
    for (const singleQuoteResponse of quoteResponse) {
        const isStreaming = singleQuoteResponse.type === constants_2.CHAINFLIP_DCA_QUOTE;
        if (isStreaming && !deps.config.REACT_APP_FEATURE_CHAINFLIP_SWAP_DCA)
            continue;
        const feeData = await getFeeData();
        if (!singleQuoteResponse.type)
            throw new Error('Missing quote type');
        if (singleQuoteResponse.boostQuote) {
            const boostRate = getChainflipQuoteRate(singleQuoteResponse.boostQuote.ingressAmountNative, singleQuoteResponse.boostQuote.egressAmountNative);
            const boostTradeRateOrQuote = {
                id: (0, uuid_1.v4)(),
                rate: boostRate,
                receiveAddress,
                quoteOrRate,
                potentialAffiliateBps: commissionBps,
                affiliateBps: commissionBps,
                isStreaming,
                slippageTolerancePercentageDecimal: input.slippageTolerancePercentageDecimal ??
                    (0, constants_1.getDefaultSlippageDecimalPercentageForSwapper)(types_1.SwapperName.Chainflip),
                steps: [
                    {
                        buyAmountBeforeFeesCryptoBaseUnit: singleQuoteResponse.boostQuote.egressAmountNative,
                        buyAmountAfterFeesCryptoBaseUnit: singleQuoteResponse.boostQuote.egressAmountNative,
                        sellAmountIncludingProtocolFeesCryptoBaseUnit: singleQuoteResponse.boostQuote.ingressAmountNative,
                        feeData: {
                            protocolFees: getProtocolFees(singleQuoteResponse.boostQuote),
                            ...feeData,
                        },
                        rate: boostRate,
                        source: getSwapSource(singleQuoteResponse.type, true),
                        buyAsset,
                        sellAsset,
                        accountNumber,
                        allowanceContract: '0x0',
                        estimatedExecutionTimeMs: (singleQuoteResponse.boostQuote.estimatedDurationsSeconds.deposit +
                            singleQuoteResponse.boostQuote.estimatedDurationsSeconds.swap) *
                            1000,
                        chainflipSpecific: {
                            chainflipNumberOfChunks: isStreaming
                                ? singleQuoteResponse.boostQuote.numberOfChunks ?? undefined
                                : undefined,
                            chainflipChunkIntervalBlocks: isStreaming
                                ? singleQuoteResponse.boostQuote.chunkIntervalBlocks ?? undefined
                                : undefined,
                            chainflipMaxBoostFee: getMaxBoostFee(),
                        },
                    },
                ],
            };
            ratesOrQuotes.push(boostTradeRateOrQuote);
        }
        const rate = getChainflipQuoteRate(singleQuoteResponse.ingressAmountNative, singleQuoteResponse.egressAmountNative);
        const tradeRateOrQuote = {
            id: (0, uuid_1.v4)(),
            rate,
            receiveAddress,
            quoteOrRate,
            potentialAffiliateBps: commissionBps,
            affiliateBps: commissionBps,
            isStreaming,
            slippageTolerancePercentageDecimal: input.slippageTolerancePercentageDecimal ??
                (0, constants_1.getDefaultSlippageDecimalPercentageForSwapper)(types_1.SwapperName.Chainflip),
            steps: [
                {
                    buyAmountBeforeFeesCryptoBaseUnit: singleQuoteResponse.egressAmountNative,
                    buyAmountAfterFeesCryptoBaseUnit: singleQuoteResponse.egressAmountNative,
                    sellAmountIncludingProtocolFeesCryptoBaseUnit: singleQuoteResponse.ingressAmountNative,
                    feeData: {
                        protocolFees: getProtocolFees(singleQuoteResponse),
                        ...feeData,
                    },
                    rate,
                    source: getSwapSource(singleQuoteResponse.type, false),
                    buyAsset,
                    sellAsset,
                    accountNumber,
                    allowanceContract: '0x0',
                    estimatedExecutionTimeMs: (singleQuoteResponse.estimatedDurationsSeconds.deposit +
                        singleQuoteResponse.estimatedDurationsSeconds.swap) *
                        1000,
                    chainflipSpecific: {
                        chainflipNumberOfChunks: isStreaming
                            ? singleQuoteResponse.numberOfChunks ?? undefined
                            : undefined,
                        chainflipChunkIntervalBlocks: isStreaming
                            ? singleQuoteResponse.chunkIntervalBlocks ?? undefined
                            : undefined,
                        chainflipMaxBoostFee: 0,
                    },
                },
            ],
        };
        ratesOrQuotes.push(tradeRateOrQuote);
    }
    return (0, monads_1.Ok)(ratesOrQuotes);
};
exports.getQuoteOrRate = getQuoteOrRate;
