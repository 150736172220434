"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPortalsTradeQuote = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const chain_adapters_1 = require("@shapeshiftoss/chain-adapters");
const utils_1 = require("@shapeshiftoss/utils");
const monads_1 = require("@sniptt/monads");
const viem_1 = require("viem");
const __1 = require("../../..");
const types_1 = require("../../../types");
const utils_2 = require("../../../utils");
const helpers_1 = require("../../utils/helpers/helpers");
const constants_1 = require("../constants");
const fetchPortalsTradeOrder_1 = require("../utils/fetchPortalsTradeOrder");
const helpers_2 = require("../utils/helpers");
async function getPortalsTradeQuote(input, assertGetEvmChainAdapter, swapperConfig) {
    const { sellAsset, buyAsset, sendAddress, accountNumber, affiliateBps, potentialAffiliateBps, chainId, supportsEIP1559, sellAmountIncludingProtocolFeesCryptoBaseUnit, } = input;
    const sellAssetChainId = sellAsset.chainId;
    const buyAssetChainId = buyAsset.chainId;
    if (!(0, helpers_2.isSupportedChainId)(sellAssetChainId)) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `unsupported chainId`,
            code: types_1.TradeQuoteError.UnsupportedChain,
            details: { chainId: sellAsset.chainId },
        }));
    }
    if (!(0, helpers_2.isSupportedChainId)(buyAssetChainId)) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `unsupported chainId`,
            code: types_1.TradeQuoteError.UnsupportedChain,
            details: { chainId: sellAsset.chainId },
        }));
    }
    if (sellAssetChainId !== buyAssetChainId) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `cross-chain not supported - both assets must be on chainId ${sellAsset.chainId}`,
            code: types_1.TradeQuoteError.CrossChainNotSupported,
            details: { buyAsset, sellAsset },
        }));
    }
    // Not a decimal percentage, just a good ol' percentage e.g 1 for 1%
    const affiliateBpsPercentage = (0, utils_1.convertBasisPointsToDecimalPercentage)(affiliateBps)
        .times(100)
        .toNumber();
    const userSlippageTolerancePercentageDecimalOrDefault = input.slippageTolerancePercentageDecimal
        ? (0, utils_1.bnOrZero)(input.slippageTolerancePercentageDecimal).times(100).toNumber()
        : (0, utils_1.bnOrZero)((0, __1.getDefaultSlippageDecimalPercentageForSwapper)(types_1.SwapperName.Portals))
            .times(100)
            .toNumber();
    if (!sendAddress)
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({ message: 'missing sendAddress' }));
    const portalsNetwork = constants_1.chainIdToPortalsNetwork[chainId];
    if (!portalsNetwork) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `unsupported ChainId`,
            code: types_1.TradeQuoteError.UnsupportedChain,
            details: { chainId: input.chainId },
        }));
    }
    const sellAssetAddress = (0, helpers_1.isNativeEvmAsset)(sellAsset.assetId)
        ? viem_1.zeroAddress
        : (0, caip_1.fromAssetId)(sellAsset.assetId).assetReference;
    const buyAssetAddress = (0, helpers_1.isNativeEvmAsset)(buyAsset.assetId)
        ? viem_1.zeroAddress
        : (0, caip_1.fromAssetId)(buyAsset.assetId).assetReference;
    const inputToken = `${portalsNetwork}:${sellAssetAddress}`;
    const outputToken = `${portalsNetwork}:${buyAssetAddress}`;
    try {
        const maybePortalsTradeOrderResponse = await (0, fetchPortalsTradeOrder_1.fetchPortalsTradeOrder)({
            sender: sendAddress,
            inputToken,
            outputToken,
            inputAmount: sellAmountIncludingProtocolFeesCryptoBaseUnit,
            slippageTolerancePercentage: userSlippageTolerancePercentageDecimalOrDefault,
            partner: (0, helpers_1.getTreasuryAddressFromChainId)(sellAsset.chainId),
            feePercentage: affiliateBpsPercentage,
            validate: true,
            swapperConfig,
        })
            .then(res => (0, monads_1.Ok)(res))
            .catch(async (err) => {
            if (err instanceof fetchPortalsTradeOrder_1.PortalsError) {
                // We assume a PortalsError was thrown because the slippage tolerance was too high during simulation
                // So we attempt another (failing) call with autoslippage which will give us the actual expected slippage
                const portalsExpectedSlippage = await (0, fetchPortalsTradeOrder_1.fetchPortalsTradeOrder)({
                    sender: sendAddress,
                    inputToken,
                    outputToken,
                    inputAmount: sellAmountIncludingProtocolFeesCryptoBaseUnit,
                    autoSlippage: true,
                    partner: (0, helpers_1.getTreasuryAddressFromChainId)(sellAsset.chainId),
                    feePercentage: affiliateBpsPercentage,
                    validate: true,
                    swapperConfig,
                })
                    // This should never happen but could in very rare cases if original call failed on slippage slightly over 2.5% but this one succeeds on slightly under 2.5%
                    .then(res => res.context.slippageTolerancePercentage)
                    .catch(err => err.message.match(/Expected slippage is (.*?)%/)?.[1]);
                // This should never happen as we don't have auto-slippage on for `/portal` as of now (2024-12-06, see https://github.com/shapeshift/web/pull/8293)
                // But as soon as Portals implement auto-slippage for the estimate endpoint, we will most likely re-enable it, assuming it actually works
                if (err.message.includes('Auto slippage exceeds'))
                    return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
                        message: err.message,
                        details: {
                            expectedSlippage: portalsExpectedSlippage
                                ? (0, utils_1.bn)(portalsExpectedSlippage).toFixed(2, utils_1.BigNumber.ROUND_HALF_UP)
                                : undefined,
                        },
                        cause: err,
                        code: types_1.TradeQuoteError.FinalQuoteMaxSlippageExceeded,
                    }));
                if (err.message.includes('execution reverted'))
                    return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
                        message: err.message,
                        details: {
                            expectedSlippage: portalsExpectedSlippage
                                ? (0, utils_1.bn)(portalsExpectedSlippage).toFixed(2, utils_1.BigNumber.ROUND_HALF_UP)
                                : undefined,
                        },
                        cause: err,
                        code: types_1.TradeQuoteError.FinalQuoteExecutionReverted,
                    }));
            }
            return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
                message: 'failed to get Portals quote',
                cause: err,
                code: types_1.TradeQuoteError.NetworkFeeEstimationFailed,
            }));
        });
        if (maybePortalsTradeOrderResponse.isErr())
            return (0, monads_1.Err)(maybePortalsTradeOrderResponse.unwrapErr());
        const portalsTradeOrderResponse = maybePortalsTradeOrderResponse.unwrap();
        const { context: { orderId, outputAmount: buyAmountAfterFeesCryptoBaseUnit, minOutputAmount: buyAmountBeforeFeesCryptoBaseUnit, slippageTolerancePercentage, target: allowanceContract, feeAmount, gasLimit, feeToken, }, tx, } = portalsTradeOrderResponse;
        const protocolFeeAsset = feeToken === inputToken ? sellAsset : buyAsset;
        if (!tx)
            throw new Error('Portals Tx simulation failed upstream');
        const inputOutputRate = (0, utils_2.getInputOutputRate)({
            sellAmountCryptoBaseUnit: input.sellAmountIncludingProtocolFeesCryptoBaseUnit,
            buyAmountCryptoBaseUnit: buyAmountAfterFeesCryptoBaseUnit,
            sellAsset,
            buyAsset,
        });
        const adapter = assertGetEvmChainAdapter(chainId);
        const { average } = await adapter.getGasFeeData();
        const networkFeeCryptoBaseUnit = chain_adapters_1.evm.calcNetworkFeeCryptoBaseUnit({
            ...average,
            supportsEIP1559: Boolean(supportsEIP1559),
            // times 1 isn't a mistake, it's just so we can write this comment above to mention that Portals already add a
            // buffer of ~15% to the gas limit
            gasLimit: (0, utils_1.bnOrZero)(gasLimit).times(1).toFixed(),
        });
        const slippageTolerancePercentageDecimal = (0, utils_1.bnOrZero)(slippageTolerancePercentage)
            .div(100)
            .toString();
        const tradeQuote = {
            id: orderId,
            quoteOrRate: 'quote',
            receiveAddress: input.receiveAddress,
            affiliateBps,
            potentialAffiliateBps,
            rate: inputOutputRate,
            slippageTolerancePercentageDecimal,
            steps: [
                {
                    accountNumber,
                    allowanceContract,
                    rate: inputOutputRate,
                    buyAsset,
                    sellAsset,
                    buyAmountBeforeFeesCryptoBaseUnit,
                    buyAmountAfterFeesCryptoBaseUnit,
                    sellAmountIncludingProtocolFeesCryptoBaseUnit: input.sellAmountIncludingProtocolFeesCryptoBaseUnit,
                    feeData: {
                        networkFeeCryptoBaseUnit,
                        protocolFees: {
                            [protocolFeeAsset.assetId]: {
                                amountCryptoBaseUnit: feeAmount,
                                asset: protocolFeeAsset,
                                requiresBalance: false,
                            },
                        },
                    },
                    source: types_1.SwapperName.Portals,
                    estimatedExecutionTimeMs: undefined,
                    portalsTransactionMetadata: tx,
                },
            ],
        };
        return (0, monads_1.Ok)(tradeQuote);
    }
    catch (err) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: 'failed to get Portals quote',
            cause: err,
            code: types_1.TradeQuoteError.NetworkFeeEstimationFailed,
        }));
    }
}
exports.getPortalsTradeQuote = getPortalsTradeQuote;
