"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChainAdapter = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const types_1 = require("@shapeshiftoss/types");
const unchained = __importStar(require("@shapeshiftoss/unchained-client"));
const types_2 = require("../../types");
const UtxoBaseAdapter_1 = require("../UtxoBaseAdapter");
const utxoSelect_1 = require("../utxoSelect");
const SUPPORTED_CHAIN_IDS = [types_1.KnownChainIds.DogecoinMainnet];
const DEFAULT_CHAIN_ID = types_1.KnownChainIds.DogecoinMainnet;
const SUPPORTED_ACCOUNT_TYPES = [types_1.UtxoAccountType.P2pkh];
class ChainAdapter extends UtxoBaseAdapter_1.UtxoBaseAdapter {
    constructor(args) {
        super({
            assetId: caip_1.dogeAssetId,
            chainId: DEFAULT_CHAIN_ID,
            rootBip44Params: ChainAdapter.rootBip44Params,
            defaultUtxoAccountType: ChainAdapter.defaultUtxoAccountType,
            parser: new unchained.dogecoin.TransactionParser({
                assetId: caip_1.dogeAssetId,
                chainId: args.chainId ?? DEFAULT_CHAIN_ID,
                midgardUrl: args.midgardUrl,
            }),
            supportedAccountTypes: SUPPORTED_ACCOUNT_TYPES,
            supportedChainIds: SUPPORTED_CHAIN_IDS,
            ...args,
        });
    }
    getDisplayName() {
        return types_2.ChainAdapterDisplayName.Dogecoin;
    }
    getName() {
        const enumIndex = Object.values(types_2.ChainAdapterDisplayName).indexOf(types_2.ChainAdapterDisplayName.Dogecoin);
        return Object.keys(types_2.ChainAdapterDisplayName)[enumIndex];
    }
    getType() {
        return types_1.KnownChainIds.DogecoinMainnet;
    }
    getFeeAssetId() {
        return this.assetId;
    }
    async getFeeData({ to, value, chainSpecific: { from, pubkey, opReturnData }, sendMax = false, }) {
        if (!to)
            throw new Error('to is required');
        if (!value)
            throw new Error('value is required');
        if (!pubkey)
            throw new Error('pubkey is required');
        const { fast, average, slow } = await this.providers.http.getNetworkFees();
        if (!(fast?.satsPerKiloByte && average?.satsPerKiloByte && slow?.satsPerKiloByte)) {
            throw new Error('UtxoBaseAdapter: failed to get fee data');
        }
        // sane default for invalid fee data from the node
        // see: https://github.com/dogecoin/dogecoin/issues/3385
        if (fast.satsPerKiloByte <= 0)
            fast.satsPerKiloByte = 500000000; // 5 DOGE per kB
        if (average.satsPerKiloByte <= 0)
            average.satsPerKiloByte = 100000000; // 1 DOGE per kB
        if (slow.satsPerKiloByte <= 0)
            slow.satsPerKiloByte = 50000000; // .5 DOGE per kB
        // ensure higher confirmation speeds never have lower fees than lower confirmation speeds
        if (slow.satsPerKiloByte > average.satsPerKiloByte)
            average.satsPerKiloByte = slow.satsPerKiloByte;
        if (average.satsPerKiloByte > fast.satsPerKiloByte)
            fast.satsPerKiloByte = average.satsPerKiloByte;
        const utxos = await this.providers.http.getUtxos({ pubkey });
        const utxoSelectInput = { from, to, value, opReturnData, utxos, sendMax };
        // We have to round because coinselect library uses sats per byte which cant be decimals
        const fastPerByte = String(Math.round(fast.satsPerKiloByte / 1000));
        const averagePerByte = String(Math.round(average.satsPerKiloByte / 1000));
        const slowPerByte = String(Math.round(slow.satsPerKiloByte / 1000));
        const { fee: fastFee } = (0, utxoSelect_1.utxoSelect)({ ...utxoSelectInput, satoshiPerByte: fastPerByte });
        const { fee: averageFee } = (0, utxoSelect_1.utxoSelect)({ ...utxoSelectInput, satoshiPerByte: averagePerByte });
        const { fee: slowFee } = (0, utxoSelect_1.utxoSelect)({ ...utxoSelectInput, satoshiPerByte: slowPerByte });
        return {
            fast: { txFee: String(fastFee), chainSpecific: { satoshiPerByte: fastPerByte } },
            average: { txFee: String(averageFee), chainSpecific: { satoshiPerByte: averagePerByte } },
            slow: { txFee: String(slowFee), chainSpecific: { satoshiPerByte: slowPerByte } },
        };
    }
}
exports.ChainAdapter = ChainAdapter;
ChainAdapter.defaultUtxoAccountType = types_1.UtxoAccountType.P2pkh;
ChainAdapter.rootBip44Params = {
    purpose: 44,
    coinType: Number(caip_1.ASSET_REFERENCE.Dogecoin),
    accountNumber: 0,
};
