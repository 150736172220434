"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTradeRate = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const monads_1 = require("@sniptt/monads");
const uuid_1 = require("uuid");
const constants_1 = require("../../../constants");
const types_1 = require("../../../types");
const utils_1 = require("../../../utils");
const fetchArbitrumBridgeSwap_1 = require("../utils/fetchArbitrumBridgeSwap");
const helpers_1 = require("../utils/helpers");
async function getTradeRate(input, { assertGetEvmChainAdapter }) {
    const { chainId, sellAsset, buyAsset, supportsEIP1559, receiveAddress, sellAmountIncludingProtocolFeesCryptoBaseUnit, sendAddress, accountNumber, } = input;
    const assertion = await (0, helpers_1.assertValidTrade)({ buyAsset, sellAsset });
    if (assertion.isErr())
        return (0, monads_1.Err)(assertion.unwrapErr());
    const isDeposit = sellAsset.chainId === caip_1.ethChainId;
    // 15 minutes for deposits, 7 days for withdrawals
    const estimatedExecutionTimeMs = isDeposit ? 15 * 60 * 1000 : 7 * 24 * 60 * 60 * 1000;
    // 1/1 when bridging on Arbitrum bridge
    const rate = '1';
    try {
        const args = {
            supportsEIP1559,
            chainId,
            buyAsset,
            sellAmountIncludingProtocolFeesCryptoBaseUnit,
            sellAsset,
            sendAddress,
            receiveAddress,
            assertGetEvmChainAdapter,
            quoteOrRate: 'rate',
        };
        const swap = await (0, fetchArbitrumBridgeSwap_1.fetchArbitrumBridgePrice)(args);
        const buyAmountBeforeFeesCryptoBaseUnit = sellAmountIncludingProtocolFeesCryptoBaseUnit;
        const buyAmountAfterFeesCryptoBaseUnit = sellAmountIncludingProtocolFeesCryptoBaseUnit;
        return (0, monads_1.Ok)({
            id: (0, uuid_1.v4)(),
            quoteOrRate: 'rate',
            accountNumber: undefined,
            receiveAddress,
            affiliateBps: '0',
            potentialAffiliateBps: '0',
            rate,
            slippageTolerancePercentageDecimal: (0, constants_1.getDefaultSlippageDecimalPercentageForSwapper)(types_1.SwapperName.ArbitrumBridge),
            steps: [
                {
                    estimatedExecutionTimeMs,
                    allowanceContract: swap.allowanceContract,
                    rate,
                    buyAsset,
                    sellAsset,
                    accountNumber,
                    buyAmountBeforeFeesCryptoBaseUnit,
                    buyAmountAfterFeesCryptoBaseUnit,
                    sellAmountIncludingProtocolFeesCryptoBaseUnit,
                    feeData: {
                        protocolFees: {},
                        networkFeeCryptoBaseUnit: swap.networkFeeCryptoBaseUnit,
                    },
                    source: types_1.SwapperName.ArbitrumBridge,
                },
            ],
            direction: isDeposit ? 'deposit' : 'withdrawal',
        });
    }
    catch (err) {
        return (0, monads_1.Err)((0, utils_1.makeSwapErrorRight)({
            message: '[ArbitrumBridge: tradeQuote] - failed to get fee data',
            cause: err,
            code: types_1.TradeQuoteError.NetworkFeeEstimationFailed,
        }));
    }
}
exports.getTradeRate = getTradeRate;
