"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTradeRate = void 0;
const getQuoteOrRate_1 = require("../utils/getQuoteOrRate");
const getTradeRate = async (input, deps) => {
    const ratesResult = await (0, getQuoteOrRate_1.getQuoteOrRate)(input, deps);
    return ratesResult.map(rates => rates.map(rate => ({
        ...rate,
        quoteOrRate: 'rate',
        steps: rate.steps.map(step => step),
    })));
};
exports.getTradeRate = getTradeRate;
